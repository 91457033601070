<template>
  <div
    class="align-center justify-center pa-4 d-flex"
    style="height: 100vh"
  >
    <v-card
      class=" pa-4 pt-7"
      max-width="448"
    >
      <v-card-title class="justify-end ma-0 pa-0">
        <v-btn
          icon
          class="ma-0 pa-0"
          @click="logoutUser"
        >
          <v-icon class="ma-0 pa-0">
            {{ icons.mdiArrowLeft }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="justify-center">
        <a
          href="https://www.anita-lee.com"
          target="_blank"
        >
          <div
            class="d-flex justify-center"
            dir="ltr"
          >
            <v-img
              :src="appLogo"
              alt="logo"
              contain
              max-height="35px"
              max-width="35px"
            ></v-img>

            <v-img
              :src="appName"
              alt="name"
              contain
              max-height="40px"
              max-width="120px"
            ></v-img>
          </div>
        </a>
      </v-card-text>

      <v-card-text class="pt-5 pb-1">
        <h5 class="text-h5 mb-1">
          <v-icon color="primary">
            {{ icons.mdiEmail }}
          </v-icon>
          {{ $t('VerifyEmail') }}
        </h5>
        <span>
          {{ $t('sent_to_email') }} <strong>{{ userData.email }}</strong> {{ $t('verification_link') }}
        </span>
        <span>
          {{ $t('please_verify_email') }}
        </span>

        <div class="align-center justify-center pt-10 text-center">
          <div>
            <v-btn
              block
              color="primary"
              @click="resendEmail"
            >
              {{ $t('Resend') }}
            </v-btn>
            <div class="pt-2">
              {{ $t('NotReceiveEmailQA') }}
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import useLogoutUser from '@/composables/useLogoutUser'
import { i18n } from '@/plugins/i18n/index'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { mdiArrowLeft, mdiEmail } from '@mdi/js'
import themeConfig from '@themeConfig'
import { useToast } from 'vue-toastification/composition'

export default {
  setup() {
    const userData = JSON.parse(localStorage.getItem('userData'))

    const toast = useToast()
    const showToast = () => toast.error(i18n.t('errorManageEvents'))
    const resendEmail = () => {
      store.dispatch('resendEmailVerification', { lang: i18n.locale }).catch(() => showToast())
    }
    const { logoutUser } = useLogoutUser()

    return {
      userData,
      resendEmail,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      logoutUser,

      icons: {
        mdiEmail,
        mdiArrowLeft,
      },
    }
  },
}
</script>
